import React, { useEffect, useState } from "react"
import Nav from "../components/nav"
import Description from "../single_use_components/about/description"
import Prices from "../components/prices"
import MyHelmet from "../components/helmet"
import Footer from "../components/footer"
import PageTransition from "gatsby-plugin-page-transitions"

export default function About() {
  const [isDark, setIsDark] = useState(false)

  useEffect(() => {
    setIsDark(window?.localStorage.getItem("isDark") === "true")
  }, [])

  return (
    <PageTransition>
      <div className={`${isDark ? "dark" : ""}`}>
        <MyHelmet />
        <Nav
          currentPage="about"
          backgroundColorProp="none"
          optionalHeader="Frequently Asked Questions"
          isDark={isDark}
          setIsDark={setIsDark}
        />
        <div className="about-me-container">
          <Description />
          <Prices additionalStyle={{ background: "none", marginTop: 50 }} />
          <Footer />
        </div>
      </div>
    </PageTransition>
  )
}
