import React from "react"
import Selfie from "../../images/selfie.jpg"
import Tilt from "react-tilt"

export default function Description() {
  return (
    <div className="about-me-description">
      <div className="text">
        <h1 className="header">- What Exactly Does Flying Fish Do? -</h1>
        <p>
          We have two main focuses. First, we want to create affordable and
          effective websites for smaller businesses who can't afford to shell
          out large amounts of money at once. Secondly, we offer more
          customizable and full-stack orientated services for the larger
          businesses that have specific project requirements.
        </p>
        <h1 className="header">- Who's Behind the Company? -</h1>
        <div style={{ display: "flex", justifyContent: "center", height: 300 }}>
          <Tilt>
            <img src={Selfie} alt="Selfie" className="selfie" />
          </Tilt>
        </div>
        <p style={{ fontSize: 11, color: "#888888" }}>
          P.S. - hover this image to tilt it...because why not?
        </p>
        <p>
          <span role="img" aria-label="wave">
            👋
          </span>{" "}
          Hi, that's me. My name is Paul Wu - I graduated from Rice University
          in 2017 and have worked as both a frontend and backend software
          engineer. I'm currently in a backend position implementing data
          integrations, but web development has always held a special place in
          my heart. I started Flying Fish because I've always wanted to run my
          own business where I'm in control of what I create.
        </p>
        <h1 className="header">- Why Choose Flying Fish Web? -</h1>
        <p>
          Basically, everything we do is really high quality. If you're a
          smaller business looking for something affordable, look no further.
          We'll create a website that looks and feels modern, looks amazing on
          phones (check this website out on a phone for example), and implements
          all the modern SEO practices. Also, you'll always be able to change
          anything you want anytime for no additional cost.
        </p>
        <p>
          If you're a larger business with specific project requirements in
          mind, we're perfect for you as well. I've worked in a professional
          software engineering setting for years now in both frontend and
          backend roles, so I can hash out specific requirements with you,
          assist in basically every part of the software development lifecycle,
          and set realistic goals and deliver on them.
        </p>
        <h1 className="header">- Why is the Standard Package Monthly? -</h1>
        <p>
          Most smaller businesses find it easier to go month by month for the
          standard five page business website. Not only is it easier to pay, but
          you're also getting a stress-free experience; you don't ever have to
          worry about deployment fees, you can always edit your website anytime
          you want, and you'll always have an experienced programmer you can
          talk to about anything (including even, well, just life). Anytime you
          need us we'll be there, and we promise to make this whole process as
          simple as possible for you.
        </p>
      </div>
    </div>
  )
}
